<template>
  <div>
    <Modal
      v-model="splitModal"
      width="600"
    >
      <p
        slot="header"
        class="text-center"
      ><span class="p-r-10">{{ taskInfo.stationName }}</span>施工计划拆分</p>
      <div>
        <div class="text-orange m-b-10">提示：点击“拆分”按钮，将对作业总数量进行平均拆分，公式：每次作业数量=作业总数量÷工作天数。</div>
        <Row class="m-b-10">
          <i-col span="20">
            <span class="p-r-5">当前作业任务总数量为</span><span class="p-r-5">{{ taskInfo.taskCount }}</span><span class="p-r-5">个，预计需要工作</span>
            <InputNumber
              size="small"
              :max="10"
              :min="1"
              v-model="days"
            ></InputNumber><span class="p-l-5">天</span>
          </i-col>
          <i-col span="4">
            <Button
              type="primary"
              size="small"
              @click="onSplit"
            >拆分</Button>
          </i-col>
        </Row>
        <Divider dashed>分配结果</Divider>
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="12"><span>作业日期</span></i-col>
          <i-col
            span="12"
            class="text-right"
          ><span>作业数量</span></i-col>
        </Row>
        <Form
          ref="formVlid"
          :model="formDynamic"
        >
          <FormItem
            v-for="(item, index) in formDynamic.items"
            :key="index"
            :prop="'items.' + index + '.actionDate'"
            :rules="{required: true, type: 'date', message: ' ', trigger: 'change'}"
          >
            <Row :gutter="8">
              <i-col span="12">
                <DatePicker
                  type="date"
                  placeholder="选择作业日期"
                  size="small"
                  transfer
                  style="width: 100%"
                  :clearable="false"
                  :options="item.dateOptions"
                  v-model="item.actionDate"
                ></DatePicker>
              </i-col>
              <i-col
                span="12"
                class="text-right"
              >
                <InputNumber
                  size="small"
                  :min="1"
                  v-model="item.taskCount"
                />
              </i-col>
            </Row>
          </FormItem>
        </Form>
      </div>
      <div slot="footer">
        <Button
          type="text"
          class="m-r-5"
          @click="splitModal = false"
        >取消</Button>
        <Button
          type="primary"
          :loading="submitLoading"
          @click="handleSubmit"
        >确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { ParseDate } from '@/utils/dateFormat'

import { dismantleWorkday } from '@/api/msp/workdaySchedule'
export default {
  data () {
    return {
      splitModal: false,
      taskInfo: {},
      days: 1,
      formDynamic: {
        items: [
          {
            actionDate: '',
            taskCount: 0
          }
        ]
      },
      submitLoading: false
    }
  },
  methods: {
    showModal (data = null) {
      const that = this
      if (!data) {
        that.$Notice.error({ desc: '非法访问，缺少必要参数' })
        return false
      }
      that.days = 1
      that.taskInfo = data
      that.formDynamic.items = [
        {
          actionDate: new Date(that.taskInfo.actionDate),
          taskCount: that.taskInfo.taskCount,
          dateOptions: {
            disabledDate (date) {
              return date && date.valueOf() < new Date(that.taskInfo.actionDate) - 86400000
            }
          }
        }
      ]
      that.splitModal = true
    },
    onSplit () {
      if (this.days > this.taskInfo.taskCount) {
        this.$Notice.warning({ desc: '当前预计工作天数大于作业任务总数量' })
        return false
      }

      this.formDynamic.items = []
      // 平均分配
      const avgCount = this.taskInfo.taskCount / this.days
      let tempTotal = 0
      for (var i = 0; i < this.days; i++) {
        let actionDate = ''
        let taskCount = parseInt(avgCount)
        if (i === 0) {
          actionDate = this.taskInfo.actionDate
        } else {
          // 其他
          const date = new Date(this.formDynamic.items[i - 1].actionDate) // 获取前一个作业日期
          date.setDate(date.getDate() + 1)
          actionDate = date

          if (i === this.days - 1) {
            // 最后一个，处理作业数量
            taskCount = this.taskInfo.taskCount - tempTotal
          }
        }

        tempTotal += taskCount

        this.formDynamic.items.push(
          {
            actionDate,
            taskCount,
            dateOptions: {
              disabledDate (date) {
                return date && date.valueOf() < new Date(actionDate) - 86400000
              }
            }
          }
        )
      }
    },
    handleSubmit () {
      const total = this.formDynamic.items.map(x => x.taskCount).reduce((prev, cur) => { return prev + cur })
      if (total !== this.taskInfo.taskCount) {
        // 拆分后总数量不等于实际作业总数量
        this.$Notice.error({ desc: '拆分后总数量与实际作业总数量不匹配，请正确分配。' })
        return false
      }

      const actionDates = this.formDynamic.items.map(x => {
        const actionDate = ParseDate(x.actionDate)
        return Object.assign(x, { actionDate })
      })
      const postDate = {
        workdayId: this.taskInfo.id,
        actionDateJsons: JSON.stringify(actionDates)
      }

      this.$Modal.confirm({
        title: '操作提示',
        content: '确认信息无误 ，并提交？',
        onOk: async () => {
          this.submitLoading = true
          const result = await dismantleWorkday(postDate).finally(() => { this.submitLoading = false })
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.splitModal = false
          }
        }
      })
    }

  }
}
</script>
