<template>
  <div>
    <div class="platform-buttom-list">
      <div
        class="bottom-button m-r-5"
        @click="goToIndex"
      >
        申请列表
      </div>
      <div class="bottom-button-active">
        申请详情
      </div>
    </div>
    <div>
      <div class="text-16">
        <span class="p-r-20">{{ taskInfo.workTypeName }}</span><span class="p-r-20">{{ taskInfo.startDate+' 至 '+taskInfo.endDate }}</span><span class="p-r-20">{{ taskInfo.code }}</span><span>状态：{{ taskInfo.statusName }}</span>
      </div>
      <Divider />
      <div class="m-b-10">
        <RadioGroup
          v-model="detailType"
          type="button"
          button-style="solid"
          size="small"
          class="m-b-10"
          @on-change="onChangeType"
        >
          <Radio
            v-for="item in detailTypeArray"
            :key="'dType_'+item.value"
            :label="item.value"
          >{{item.name}}</Radio>
        </RadioGroup>
      </div>
      <Row
        :gutter="8"
        class="m-b-10"
      >
        <i-col span="3">
          <DatePicker
            type="daterange"
            placeholder="选择起止日期"
            size="small"
            transfer
            style="width: 100%"
            @on-change="onChangeDates"
          ></DatePicker>
        </i-col>
        <i-col span="3">
          <Select
            size="small"
            placeholder="线路"
            v-model="query.assetId"
            transfer
            clearable
            @on-change="onChangeAsset"
          >
            <i-option
              v-for="item in assetArray"
              :key="'aid_' + item.id"
              :value="item.id"
            >
              {{ item.name }}
            </i-option>
          </Select>
        </i-col>
        <i-col span="3">
          <Select
            size="small"
            placeholder="站点"
            v-model="query.stationId"
            transfer
            clearable
          >
            <i-option
              v-for="item in stationArray"
              :key="'aid_' + item.id"
              :value="item.id"
            >
              {{ item.name }}
            </i-option>
          </Select>
        </i-col>
        <!-- <i-col span="4">
          <i-input
            v-model.trim="query.keyword"
            size="small"
            placeholder="关键字：编号"
          ></i-input>
        </i-col> -->
        <i-col span="8">
          <Button
            type="primary"
            icon="ios-search"
            size="small"
            class="m-r-5"
            @click="onSearch"
          >搜索</Button>
          <Button
            v-if="taskInfo.status===0"
            type="success"
            size="small"
            @click="onSubmitApply"
          >提交申请</Button>
        </i-col>
        <i-col
          span="7"
          class="text-right"
        >
          <Button
            v-if="taskInfo.status===0"
            type="primary"
            size="small"
            @click="onAdd"
          >添加施工计划</Button>
        </i-col>
      </Row>
      <div>
        <Table
          stripe
          max-height="620"
          size="small"
          :data="tableList"
          :columns="tableColumns"
          :loading="tableLoading"
        ></Table>
        <div class="paging_style">
          <Page
            size="small"
            :total="total"
            :page-size="query.pageSize"
            show-total
            show-elevator
            show-sizer
            :page-size-opts="pagesizeSetting"
            @on-page-size-change='onChangePageSize'
            :current="query.pageNumber"
            @on-change="onChangePageNum"
          ></Page>
        </div>
      </div>
    </div>
    <plan-split ref="planSplit" />
    <plan-add
      v-if="showPlanAdd"
      ref="planAdd"
      :tId="id"
      :onSuccess="planAddSuccess"
    />
    <plan-detail
      ref="planDetail"
      :isModify="true"
      :onSuccess="initData"
    />
    <view-task-file ref="viewTaskFile" />
  </div>
</template>

<script>
import { indexMixins } from '@/components/constructionApply/mixins'

import { getTableWorkdayPage, getWorkdayTableInfo, removeWorkday, submitWorkday } from '@/api/msp/workdayTable'
import { getTableTaskPage } from '@/api/msp/workdaySchedule'

import PlanSplit from './PlanSplit.vue'
import PlanAdd from './PlanAdd.vue'
import PlanDetail from '@/components/constructionApply/components/PlanDetail.vue'
import ViewTaskFile from '@/components/constructionApply/components/ViewTaskFile.vue'

export default {
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  mixins: [indexMixins],
  components: { PlanSplit, PlanAdd, PlanDetail, ViewTaskFile },
  data () {
    return {
      detailType: 1,
      detailTypeArray: [
        { value: 1, name: '站点汇总显示' },
        { value: 2, name: '任务明细显示' }
      ],
      taskInfo: {},
      assetArray: [],
      stationArray: [],
      showPlanAdd: false,
      query: {
        pageNumber: 1,
        pageSize: 15,
        tableId: null,
        assetId: null,
        startDate: '',
        endDate: '',
        stationId: null,
        supplierCompanyId: null
      },
      tableList: [],
      total: 0,
      tableLoading: false,
      tableColumns: [],
      typeShowColumns: new Map([
        [1, ['actionDate', 'companyName', 'assetName', 'stationName', 'position', 'workTime', 'taskCount', 'taskTypes', 'workerNumber', 'supplierCompanyName', 'workManagerName', 'remark', 'operate']], // 站点汇总显示
        [2, ['code', 'actionDate', 'assetName', 'stationName', 'position', 'deviceModelName', 'taskFile', 'taskName', 'createCompanyName', 'taskTypeName', 'supplierCompanyName']] // 任务明细显示
      ])
    }
  },
  mounted () {
    this.query.tableId = this.id
    this.tableColumns = this.getTableColumns()
    this.getAssets()
    this.getTaskInfo()
    this.initData()
  },
  methods: {
    goToIndex () {
      this.$router.push({ name: 'construction-apply-index' })
    },
    getTableColumns () {
      const defineColumns = {
        code: { title: '资源编号', align: 'center', minWidth: 120, key: 'code' },
        actionDate: { title: '作业日期', align: 'center', minWidth: 120, key: 'actionDate' },
        companyName: { title: '任务发起方', align: 'center', minWidth: 120, key: 'companyName' },
        createCompanyName: { title: '任务发起方', align: 'center', minWidth: 120, key: 'createCompanyName' },
        assetName: { title: '线路', align: 'center', minWidth: 120, key: 'assetName' },
        stationName: { title: '站点', align: 'center', minWidth: 120, key: 'stationName' },
        position: {
          title: '位置',
          align: 'center',
          minWidth: 120,
          render: (h, { row }) => {
            const html = []
            row.positionList.forEach(item => {
              html.push(h('p', `${item.floor}层 - ${item.positionName}`))
            })
            return h('div', html)
          }
        },
        deviceModelName: { title: '资源类型', align: 'center', minWidth: 120, key: 'deviceModelName' },
        workTime: { title: '作业时间段', align: 'center', minWidth: 120, key: 'workTime' },
        taskCount: { title: '任务数量', align: 'center', minWidth: 120, key: 'taskCount' },
        taskName: { title: '作业名称', align: 'center', minWidth: 120, key: 'taskName' },
        taskTypes: { title: '任务类型', align: 'center', minWidth: 120, key: 'taskTypes' },
        taskTypeName: { title: '任务类型', align: 'center', minWidth: 120, key: 'taskTypeName' },
        workerNumber: { title: '施工人数', align: 'center', minWidth: 120, key: 'workerNumber' },
        supplierCompanyName: { title: '施工单位', align: 'center', minWidth: 120, key: 'supplierCompanyName' },
        remark: { title: '备注', align: 'center', minWidth: 120, key: 'remark' },
        workManagerName: {
          title: '带班人员',
          align: 'center',
          minWidth: 100,
          key: 'workManagerName',
          render: (h, { row }) => {
            return h('span', row.workManagerName || '/')
          }
        },
        taskFile: {
          title: '作业画面',
          align: 'center',
          minWidth: 120,
          render: (h, { row }) => {
            if (row.taskitemFileList && row.taskitemFileList.length > 0) {
              if (row.taskitemFileList.some(x => (x.mimeType.includes('video') || x.mimeType.includes('audio')))) {
                return h('a', {
                  on: {
                    click: () => {
                      this.$nextTick(() => {
                        this.$refs.viewTaskFile.showModal(row)
                      })
                    }
                  },
                  style: {
                    cursor: 'pointer'
                  }
                }, '查看素材')
              } else {
                return h('div', [
                  h(
                    'img',
                    {
                      domProps: {
                        src: row.taskitemFileList[0].fileKey + '?x-oss-process=image/resize,w_64'
                      },
                      on: {
                        click: () => {
                          this.$nextTick(() => {
                            this.$refs.viewTaskFile.showModal(row)
                          })
                        }
                      },
                      style: {
                        width: '64px',
                        cursor: 'pointer'
                      }
                    }
                  )
                ])
              }
            } else {
              return h('span', '暂无画面')
            }
          }
        },
        operate: {
          title: '操作',
          align: 'center',
          minWidth: 180,
          fixed: 'right',
          render: (h, { row }) => {
            const html = [
              h('a', {
                class: ['m-r-5'],
                on: {
                  click: () => {
                    this.showDetail(row)
                  }
                }
              }, '详情')
            ]

            if (row.status === 0) { // 草稿
              if (row.taskCount > 1) {
                html.push(
                  h('a', {
                    class: ['m-r-5'],
                    on: {
                      click: () => {
                        this.onSplit(row)
                      }
                    }
                  }, '拆分')
                )
              }
              html.push(

                h('a', {
                  style: {
                    color: '#ef4f4f'
                  },
                  on: {
                    click: () => {
                      this.onRemove(row)
                    }
                  }
                }, '移除')
              )
            }

            return h('div', html)
          }
        }
      }
      const data = []
      var showColumns = this.typeShowColumns.get(this.detailType)
      if (!showColumns || showColumns.length === 0) {
        showColumns = this.typeShowColumns.get(1)
      }
      showColumns.forEach(col => data.push(defineColumns[col]))

      return data
    },
    initData () {
      switch (this.detailType) {
        case 1:
          this.getTableData()
          break
        case 2:
          this.getTaskTableData()
          break
      }
    },
    planAddSuccess () {
      this.initData()
      // 调用父级重新加载右侧数据方法
      this.$emit('reloadRight')
    },
    /**
     * 站点汇总显示
     */
    async getTableData () {
      this.tableLoading = true
      const result = await getTableWorkdayPage(this.query).finally(() => { this.tableLoading = false })
      if (result && !result.errcode) {
        this.tableList = result.list
        this.total = result.totalRow
      }
    },
    /**
     * 分页查询施工计划表的任务明细
     */
    async getTaskTableData () {
      this.tableLoading = true
      const result = await getTableTaskPage(this.query).finally(() => { this.tableLoading = false })
      if (result && !result.errcode) {
        this.tableList = result.list
        this.total = result.totalRow
      }
    },
    /**
     * 获取详细信息
     */
    async getTaskInfo () {
      const result = await getWorkdayTableInfo({ workdayTableId: this.id })
      if (result && !result.errcode) {
        this.taskInfo = result
      }
    },
    async getAssets () {
      this.assetArray = await this.getAssetData()
    },
    async onChangeAsset () {
      this.stationArray = await this.getStationData(this.query.assetId)
    },
    onChangeType () {
      this.tableColumns = this.getTableColumns()
      this.initData()
    },
    onChangeDates (dates) {
      this.query.startDate = dates[0]
      this.query.endDate = dates[1]
    },
    onChangePageNum (curPage) {
      this.query.pageNumber = curPage
      this.initData()
    },
    onChangePageSize (curPageSize) {
      this.query.pageNumber = 1
      this.query.pageSize = curPageSize
      this.initData()
    },
    onSearch () {
      this.query.pageNumber = 1
      this.initData()
    },
    showDetail (obj) {
      this.$nextTick(() => {
        this.$refs.planDetail.showModal(obj)
      })
    },
    onSplit (obj) {
      this.$nextTick(() => {
        this.$refs.planSplit.showModal(obj)
      })
    },
    onRemove (obj) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要移除改项？',
        onOk: async () => {
          const postData = {
            tableId: this.id,
            workdayId: obj.id
          }
          const result = await removeWorkday(postData)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.getTableData()
            // 调用父级重新加载右侧数据方法
            this.$emit('reloadRight')
          }
        }
      })
    },
    onSubmitApply () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定信息无误，并提交？',
        onOk: async () => {
          const postData = {
            tableId: this.id
          }
          const result = await submitWorkday(postData)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.getTaskInfo()
            this.initData()
          }
        }
      })
    },
    onAdd () {
      this.showPlanAdd = true
      this.$nextTick(() => {
        this.$refs.planAdd.showModal()
      })
    }

  }
}
</script>
