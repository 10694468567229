<template>
  <div>
    <Modal
      v-model="addModal"
      width="70%"
    >
      <p
        slot="header"
        class="text-center"
      >添加施工计划</p>
      <div>
        <Row
          :gutter="8"
          class="m-b-10"
        >
          <i-col span="3">
            <DatePicker
              type="daterange"
              placeholder="选择起止日期"
              size="small"
              transfer
              style="width: 100%"
              @on-change="onChangeDates"
            ></DatePicker>
          </i-col>
          <i-col span="3">
            <Select
              size="small"
              placeholder="线路"
              v-model="query.assetId"
              transfer
              clearable
              @on-change="onChangeAsset"
            >
              <i-option
                v-for="item in assetArray"
                :key="'aid_' + item.id"
                :value="item.id"
              >
                {{ item.name }}
              </i-option>
            </Select>
          </i-col>
          <i-col span="3">
            <Select
              size="small"
              placeholder="站点"
              v-model="query.stationId"
              transfer
              clearable
            >
              <i-option
                v-for="item in stationArray"
                :key="'aid_' + item.id"
                :value="item.id"
              >
                {{ item.name }}
              </i-option>
            </Select>
          </i-col>
          <!-- <i-col span="4">
            <i-input
              v-model.trim="query.keyword"
              size="small"
              placeholder="关键字：编号"
            ></i-input>
          </i-col> -->
          <i-col span="4">
            <Button
              type="primary"
              icon="ios-search"
              size="small"
              class="m-r-5"
              @click="onSearch"
            >搜索</Button>
          </i-col>
        </Row>
        <div>
          <Table
            stripe
            max-height="620"
            size="small"
            :data="tableList"
            :columns="tableColumns"
            :loading="tableLoading"
            @on-selection-change="selectionChange"
          ></Table>
          <div class="paging_style">
            <Page
              size="small"
              :total="total"
              :page-size="query.pageSize"
              show-total
              show-elevator
              show-sizer
              :page-size-opts="pagesizeSetting"
              @on-page-size-change='onChangePageSize'
              :current="query.pageNumber"
              @on-change="onChangePageNum"
            ></Page>
          </div>
        </div>
      </div>
      <div slot="footer">
        <Button
          type="text"
          class="m-r-5"
          @click="addModal = false"
        >取消</Button>
        <Button
          type="primary"
          :disabled="btnSubmit"
          @click="onConfirm"
        >确认添加</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { indexMixins } from '@/components/constructionApply/mixins'
import { getWorkdayPage } from '@/api/msp/workdaySchedule'
import { addWorkdayToTable } from '@/api/msp/workdayTable'
export default {
  props: {
    tId: {
      type: Number,
      required: true
    },
    onSuccess: {
      type: Function,
      default: () => { }
    }
  },
  mixins: [indexMixins],
  data () {
    return {
      addModal: false,
      tableId: null,
      assetArray: [],
      stationArray: [],
      query: {
        pageNumber: 1,
        pageSize: 15,
        isAddTable: false,
        startDate: '',
        endDate: '',
        assetId: null,
        stationId: null,
        keyword: ''
      },
      selectedId: [], // 选中项id
      tableList: [],
      total: 0,
      tableLoading: false,
      tableColumns: [
        { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        { title: '作业日期', align: 'center', minWidth: 120, key: 'actionDate' },
        { title: '任务发起方', align: 'center', minWidth: 120, key: 'companyName' },
        { title: '线路', align: 'center', minWidth: 120, key: 'assetName' },
        { title: '站点', align: 'center', minWidth: 120, key: 'stationName' },
        {
          title: '位置',
          align: 'center',
          minWidth: 120,
          render: (h, { row }) => {
            const html = []
            row.positionList.forEach(item => {
              html.push(h('p', `${item.floor}层 - ${item.positionName}`))
            })
            return h('div', html)
          }
        },
        { title: '作业时间段', align: 'center', minWidth: 120, key: 'workTime' },
        { title: '任务数量', align: 'center', minWidth: 120, key: 'taskCount' },
        { title: '任务类型', align: 'center', minWidth: 120, key: 'taskTypes' },
        { title: '施工人数', align: 'center', minWidth: 120, key: 'workerNumber' },
        { title: '施工单位', align: 'center', minWidth: 120, key: 'supplierCompanyName' },
        { title: '备注', align: 'center', minWidth: 120, key: 'remark' }
      ]
    }
  },
  computed: {
    btnSubmit () {
      return this.selectedId.length === 0
    }
  },
  methods: {
    showModal () {
      this.tableId = this.tId
      this.query.startDate = ''
      this.query.endDate = ''
      this.query.assetId = null
      this.query.stationId = null
      this.query.keyword = ''
      this.getAssets()
      this.getTableData()
      this.addModal = true
    },
    async getTableData () {
      this.tableLoading = true
      const result = await getWorkdayPage(this.query).finally(() => { this.tableLoading = false })
      if (result && !result.errcode) {
        this.tableList = result.list
        this.total = result.totalRow
      }
    },
    async getAssets () {
      this.assetArray = await this.getAssetData()
    },
    async onChangeAsset () {
      this.stationArray = await this.getStationData(this.query.assetId)
    },
    onChangeDates (dates) {
      this.query.startDate = dates[0]
      this.query.endDate = dates[1]
    },
    onChangePageNum (curPage) {
      this.selectedId = []
      this.query.pageNumber = curPage
      this.getTableData()
    },
    onChangePageSize (curPageSize) {
      this.query.pageNumber = 1
      this.query.pageSize = curPageSize
      this.getTableData()
    },
    onSearch () {
      this.query.pageNumber = 1
      this.selectedId = []
      this.getTableData()
    },
    selectionChange (selections) {
      this.selectedId = selections.map(x => x.id)
    },
    onConfirm () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确认信息无误，并提交？',
        onOk: async () => {
          const postData = {
            tableId: this.tId,
            workdayIds: JSON.stringify(this.selectedId)
          }
          const result = await addWorkdayToTable(postData)
          if (result && result.success) {
            this.$Notice.success({ desc: '操作成功' })
            this.selectedId = []
            this.addModal = false
            this.onSuccess()
          }
        }
      })
    }
  }
}
</script>
